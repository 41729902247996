html,
body {
  font-family: system-ui;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* iOS Safari */
  user-select: none;
}

.viewport {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .cells-view {
    flex: 1;
    overflow: auto;
    display: grid;

    .cells-container {
      display: grid;
      justify-self: center;
      border: 2px solid #666;
      border-radius: 8px;
      height: min-content;
    }

    .cell {
      user-select: none;
      cursor: pointer;
      position: relative;

      .cell-content {
        // disable flex to prevent layout shift
        // display: inline-flex;
        // justify-content: center;
        // align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
      }

      &.solution-reveal {
        background: slategray;
      }
      &.solution-flag {
        background: brown;
      }
    }

    &.state-idle,
    &.state-playing {
      .cell {
        &:hover {
          outline: 1px dashed #66666666;
        }

        &:not(.state-revealed) {
          &:hover {
            filter: brightness(1.25);
          }

          &.pointer-reveal {
            filter: opacity(0.25);
          }
          &:not(.state-flagged).pointer-dig-surroundings {
            filter: opacity(0.25);
          }
        }
      }
    }
  }

  .solutions {
    .table-wrapper {
      max-height: 180px;
      overflow: auto;
    }
  }

  .emoji-center {
    // Enlarge and down scale to align emoji to center in Chromium based browsers
    // font-size: 48px;
    // transform: scale(0.5);
    font-size: 24px;
    line-height: 32px;
  }
}
